<template>
  <div class="Contd">
    <div class="left">
      <!-- 头像 -->
      <div class="toux">
        <img src="@/assets/imgs/biank.png" alt="">
      </div>

      <!-- 简介框1 -->
      <div class="jk1">
        <div>我们太看重白昼</div>
        <div>又太过忽视黑夜</div>
      </div>

      <div class="jk2">
        <div class="jkDome">Javascript.No1 🐉</div>
        <div class="jkDome">Vue.js ❤️</div>
        <div class="jkDome">React 💻</div>
        <div class="jkDome">Node.jsExpress🛰️</div>
        <div class="jkDome">PHP 🎮</div>
        <div class="jkDome">Mysql 📕</div>
        <div class="jkDome">努力开发做一个全栈✌</div>
      </div>

      <div>
        <div class="jk3">
          <img src="@/assets/imgs/1.gif" style="width: 100%;height: 200px;border-radius: 10px;" alt="">
        </div>
      </div>
    </div>
    <!-- 中间内容 -->
    <div class="cont">
      <!-- 网站主标题 -->
      <div class="title"> 小七 No1 ----网站正在装修请稍后访问....</div>
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.Contd {
  padding-top: 80px;
  display: flex
}

.top {
  width: 200px;
  height: 100px;
  background-color: #fffefe49;
  box-sizing: 0 0 0px 20px #fff;
  border-radius: 5px;
}

.cont {
  width: 800px;
  /* border: 1px solid #000; */
  padding: 20px;

  .title {
    /* width: 160px; */
    font-size: 30px;
    color: #fff;
    font-family: fantasy;
    text-shadow: 0 0 0.5em #24e775da, 0 0 0.2em #5c5c5c;
  }


}


.jk1 {
  width: 180px;
  margin: auto;
  margin-top: 20px;
  background-color: #ffffff30;
  box-sizing: 0 0 0px 20px #fff;
  border-radius: 10px;
  color: #fbf8f8;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: cente;
  padding: 18px;
  font-family: monospace;

  div {
    margin-top: 5px;
  }

  cursor: pointer;
}

.jk2 {
  width: 180px;
  height: 200px;
  margin: auto;
  margin-top: 20px;
  background-color: #ffffff30;
  box-sizing: 0 0 0px 20px #fff;
  border-radius: 10px;
  color: #fbf8f8;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 19px;

  .jkDome {
    padding: 5px;
    background-color: #00000029;
    height: 22px;
    border-radius: 10px;
    margin: 5px;
    line-height: 22px;
    font-size: 12px;
    font-family: monospace;
  }
}

.jk3 {
  width: 180px;
  margin: auto;
  margin-top: 20px;
  background-color: #ffffff30;
  box-sizing: 0 0 0px 20px #fff;
  border-radius: 10px;
  color: #fbf8f8;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: cente;
  padding: 18px;
  font-family: monospace;
}

.jk1:hover {
  /* 动画慢慢变白 */
  transition: 0.5s;
  background-color: #170909cd;
}

.left {
  padding-top: 30px;
  width: 300px;
  height: 80vh;
  overflow-y: scroll;
  /* 隐藏滚动条 */
  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */


}

.toux {
  margin: auto;
  width: 130px;
  height: 130px;
  background-image: url("../assets/imgs/toux.jpg");
  background-size: 100% 100%;
  border-radius: 50%;
  position: relative;

  img {
    position: absolute;
    width: 150%;
    height: 150%;
    border-radius: 50%;
    top: -35px;
    left: -34px;

  }
}
</style>