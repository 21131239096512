<template>
  <div class="Cont">
    <router-view />
  </div>

</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

body {
  background-image: url("./assets/imgs/77.jpg");
  background-size: 100% 100%;
  height: 100vh;
}

.Cont {
  width: 1200px;
  margin: 0 auto;

}
</style>
